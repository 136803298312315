import * as _ from "lodash"
import callAPI from "../lib/callAPI"
import LogRocket from "logrocket"
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const SESSION_TOKEN_NAME = "upnorth-shop-session-token"

const linkLogRocket = customer => {
  if (customer && process.env.GATSBY_LOGROCKET) {
    LogRocket.identify(customer.id_customer, {
      email: customer.email,
    })
  }
}

const sessionInitialState = {
  initialized: false,
  token: null,
  disabled: false,
  links: null,
  customer: null,
  login: false,
  logout: false,
  creatingaddress: false,
  updatingaddress: null,
  deletingaddress: null,
  creatingprofile: false,
  updatingprofile: false,
  subscribing: false,
  unsubscribing: false,
}

class SessionProvider {
  use(dispatch, store) {
    this.store = store
    this.dispatch = dispatch
  }

  clone(o) {
    return _.cloneDeep(o)
  }

  async load(clonedState) {
    if (typeof localStorage !== undefined) {
      try {
        // this is needed as callAPI gets token from store
        this.store.session.token = localStorage.getItem(SESSION_TOKEN_NAME)

        if (
          this.store.session.token &&
          this.store.session.token !== "undefined" &&
          this.store.session.token.length > 0
        ) {
          const { session, customer, _links } = await callAPI(
            this.store,
            this.store.app.endpoints.me
          )
          if (session && customer) {
            this.store.session.customer = customer
            if (this.store.session.token !== session.token) {
              localStorage.setItem(SESSION_TOKEN_NAME, session.token)
              this.store.session.token = session.token
            }

            linkLogRocket(customer)

            clonedState.session.initialized = true
            clonedState.session.disabled = false
            clonedState.session.customer = customer
            clonedState.session.links = _links
            clonedState.session.token = session.token
          } else {
            console.error("SessionProvider.load error")
            throw new Error() // @todo
          }
        }
      } catch (e) {
        console.error("SessionProvider.load", e)
      }
    }

    return clonedState
  }

  /* ************************************************** */

  async resetPassword(email, lang) {
    await this.dispatch(s => {
      s.session.disabled = true
      return s
    })

    try {
      const result = await callAPI(
        this.store,
        this.store.app.endpoints.password_reset,
        {
          email: email,
          id_lang: lang.id_lang,
        }
      )

      await this.dispatch(s => {
        s.session.disabled = false
        return s
      })

      return result
    } catch (e) {
      console.warn(e)
      await this.dispatch(s => {
        s.session.disabled = false
        return s
      })
      return null
    }
  }

  async setPassword(id_customer, token, reset_token, password, callback) {
    await this.dispatch(s => {
      s.session.disabled = true
      return s
    })

    try {
      const { _links, session, customer } = await callAPI(
        this.store,
        this.store.app.endpoints.password_set,
        {
          id_customer: id_customer,
          token: token,
          reset_token: reset_token,
          password: password,
        }
      )

      if (session && session.token && customer) {
        if (this.store.session.token !== session.token) {
          localStorage.setItem(SESSION_TOKEN_NAME, session.token)
          this.store.session.token = session.token
        }

        await this.dispatch(s => {
          s.session.disabled = false
          s.session.login = false
          s.session.links = _links
          s.session.customer = customer
          s.session.token = session.token
          return s
        })

        if (callback) callback()
      } else {
        await this.dispatch(s => {
          s.session.disabled = false
          return s
        })
      }
    } catch (e) {
      console.warn(e)
      await this.dispatch(s => {
        s.session.disabled = false
        return s
      })
    }
  }

  /* ************************************************** */

  async logout() {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.logout = true
      return s
    })

    if (typeof localStorage !== undefined) {
      localStorage.removeItem(SESSION_TOKEN_NAME)
    }

    await this.dispatch(s => {
      s.session.disabled = false
      s.session.logout = false
      s.session.token = null
      s.session.disabled = false
      s.session.links = null
      s.session.customer = null
      // clear other contexts too
      s.cart.cart = null
      s.cart.checkout = null
      s.cart.token = null
      return s
    })
  }

  async login(email, password, lang, callback) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.login = true
      return s
    })

    try {
      const { session, customer, _links } = await callAPI(
        this.store,
        this.store.app.endpoints.login,
        {
          email: email,
          password: password,
        }
      )

      if (session && session.token && customer) {
        if (this.store.session.token !== session.token) {
          localStorage.setItem(SESSION_TOKEN_NAME, session.token)
          this.store.session.token = session.token
        }

        linkLogRocket(customer)

        await this.dispatch(s => {
          s.session.disabled = false
          s.session.login = false
          s.session.links = _links
          s.session.customer = customer
          s.session.token = session.token
          return s
        })

        return {
          session: session,
          customer: customer,
          _links: _links,
        }
      } else {
        await this.dispatch(s => {
          s.session.disabled = false
          s.session.login = false
          s.session.links = null
          s.session.customer = null
          s.session.token = null
          return s
        })
      }
    } catch (e) {
      this.dispatch(s => {
        s.session.disabled = false
        s.session.login = false
        s.session.links = null
        s.session.customer = null
        s.session.token = null
        return s
      })
      throw e
    }
  }

  /* ************************************************** */

  async createGuest(deliveryAddress, invoiceAddress, guest) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.creatingprofile = true
      return s
    })

    const params = {
      account: {
        id_lang: guest.lang.id_lang,
        email: guest.email,
        password: guest.password || undefined,
        id_gender: guest.gender,
        firstname: guest.firstname,
        lastname: guest.lastname,
        newsletter: guest.newsletter ?? false
      },
      delivery: {
        address1: deliveryAddress.address1,
        address2: deliveryAddress.address2,
        postcode: deliveryAddress.postcode,
        city: deliveryAddress.city,
        id_country: deliveryAddress.id_country,
        firstname: deliveryAddress.firstname,
        lastname: deliveryAddress.lastname,
        company: deliveryAddress.company
      },
      invoice: {
        address1: invoiceAddress.address1,
        address2: invoiceAddress.address2,
        postcode: invoiceAddress.postcode,
        city: invoiceAddress.city,
        id_country: invoiceAddress.id_country,
        firstname: invoiceAddress.firstname,
        lastname: invoiceAddress.lastname,
        company: invoiceAddress.company,
        vat_number: invoiceAddress.vat_number
      }
    }

    try {
      const response = await callAPI(
        this.store,
        this.store.app.endpoints.create_guest,
        params
      )

      const { customer, session, _links } = response

      if (session && session.token && customer) {
        if (this.store.session.token !== session.token) {
          localStorage.setItem(SESSION_TOKEN_NAME, session.token)
          this.store.session.token = session.token
        }

        linkLogRocket(customer)

        await this.dispatch(s => {
          s.session.disabled = false
          s.session.creatingprofile = false
          s.session.links = _links
          s.session.customer = customer
          s.session.token = session.token
          return s
        })
      } else {
        await this.dispatch(s => {
          s.session.disabled = false
          s.session.creatingprofile = false
          s.session.links = null
          s.session.customer = null
          s.session.token = null
          return s
        })
      }

      customer._links = _links
      return customer
    } catch (e) {
      this.dispatch(s => {
        s.session.disabled = false
        s.session.creatingprofile = false
        s.session.links = null
        s.session.customer = null
        s.session.token = null
        return s
      })
      throw e
    }
  }

  async setContact(id_customer, deliveryAddress, invoiceAddress) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.creatingprofile = true
      return s
    })

    const params = {
      id_customer: id_customer,
      delivery: {
        address1: deliveryAddress.address1,
        address2: deliveryAddress.address2,
        postcode: deliveryAddress.postcode,
        city: deliveryAddress.city,
        id_country: deliveryAddress.id_country,
        firstname: deliveryAddress.firstname,
        lastname: deliveryAddress.lastname,
        company: deliveryAddress.company
      },
      invoice: {
        address1: invoiceAddress.address1,
        address2: invoiceAddress.address2,
        postcode: invoiceAddress.postcode,
        city: invoiceAddress.city,
        id_country: invoiceAddress.id_country,
        firstname: invoiceAddress.firstname,
        lastname: invoiceAddress.lastname,
        company: invoiceAddress.company,
        vat: invoiceAddress.vat
      }
    }

    try {
      console.log(this.store.app.endpoints)

      const response = await callAPI(
        this.store,
        this.store.app.endpoints.set_contact,
        params
      )

      const { customer, session, _links } = response

      if (session && session.token && customer) {
        if (this.store.session.token !== session.token) {
          localStorage.setItem(SESSION_TOKEN_NAME, session.token)
          this.store.session.token = session.token
        }

        linkLogRocket(customer)

        await this.dispatch(s => {
          s.session.disabled = false
          s.session.creatingprofile = false
          s.session.links = _links
          s.session.customer = customer
          s.session.token = session.token
          return s
        })
      } else {
        await this.dispatch(s => {
          s.session.disabled = false
          s.session.creatingprofile = false
          s.session.links = null
          s.session.customer = null
          s.session.token = null
          return s
        })
      }

      customer._links = _links
      return customer
    } catch (e) {
      this.dispatch(s => {
        s.session.disabled = false
        s.session.creatingprofile = false
        s.session.links = null
        s.session.customer = null
        s.session.token = null
        return s
      })
      throw e
    }
  }

  /* ************************************************** */

  async create(account) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.creatingprofile = true
      return s
    })

    const accountParams = {
      id_lang: account.lang.id_lang,
      email: account.email,
      password: account.password,
      id_gender: account.gender,
      firstname: account.firstname,
      lastname: account.lastname,
      newsletter: account.newsletter ?? false,

      address1: account.address1 ?? undefined,
      address2: account.address2 ?? undefined,
      city: account.city ?? undefined,
      id_country: +account.id_country ?? undefined,
      postcode: account.postcode ?? undefined,
    }

    try {
      const response = await callAPI(
        this.store,
        this.store.app.endpoints.create_account,
        accountParams
      )

      const { customer, session, _links } = response

      if (session && session.token && customer) {
        if (this.store.session.token !== session.token) {
          localStorage.setItem(SESSION_TOKEN_NAME, session.token)
          this.store.session.token = session.token
        }

        linkLogRocket(customer)

        await this.dispatch(s => {
          s.session.disabled = false
          s.session.creatingprofile = false
          s.session.links = _links
          s.session.customer = customer
          s.session.token = session.token
          return s
        })
      } else {
        await this.dispatch(s => {
          s.session.disabled = false
          s.session.creatingprofile = false
          s.session.links = null
          s.session.customer = null
          s.session.token = null
          return s
        })
      }

      return customer
    } catch (e) {
      this.dispatch(s => {
        s.session.disabled = false
        s.session.creatingprofile = false
        s.session.links = null
        s.session.customer = null
        s.session.token = null
        return s
      })
      throw e
    }
  }

  /* ************************************************** */

  async updateProfile(id_gender, firstname, lastname, email) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.updatingprofile = true
      return s
    })

    const result = await callAPI(
      this.store,
      this.store.session.links.updateprofile,
      {
        customer: {
          id_gender: id_gender,
          firstname: firstname,
          lastname: lastname,
          email: email,
        },
      }
    )

    await this.dispatch(s => {
      s.session.disabled = false
      s.session.updatingprofile = false
      return s
    })

    return result
  }

  /* ************************************************** */

  async getNewsletter() {
    return callAPI(this.store, this.store.app.endpoints.my_newsletter)
  }

  async subscribeNewsletter(newsletter) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.subscribing = true
      return s
    })

    const result = await callAPI(this.store, newsletter._links.subscribe)

    await this.dispatch(s => {
      s.session.disabled = false
      s.session.subscribing = false
      return s
    })

    return result
  }

  async unsubscribeNewsletter(newsletter) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.unsubscribing = true
      return s
    })

    const result = await callAPI(this.store, newsletter._links.unsubscribe)

    await this.dispatch(s => {
      s.session.disabled = false
      s.session.unsubscribing = false
      return s
    })

    return result
  }

  /* ************************************************** */

  async createAddress(address) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.creatingaddress = true
      return s
    })

    const result = await callAPI(
      this.store,
      this.store.app.endpoints.create_address,
      {
        address: address,
      }
    )

    await this.dispatch(s => {
      s.session.disabled = false
      s.session.creatingaddress = false
      return s
    })

    return result
  }

  async updateAddress(address) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.updatingaddress = address.id_address
      return s
    })

    const result = await callAPI(this.store, address._links.update, {
      address: address,
    })

    await this.dispatch(s => {
      s.session.disabled = false
      s.session.updatingaddress = null
      return s
    })

    return result
  }

  async deleteAddress(address) {
    await this.dispatch(s => {
      s.session.disabled = true
      s.session.deletingaddress = address.id_address
      return s
    })

    const result = await callAPI(this.store, address._links.delete)

    await this.dispatch(s => {
      s.session.disabled = false
      s.session.deletingaddress = null
      return s
    })

    return result
  }

  /* ************************************************** */
}

export { SessionProvider, sessionInitialState }
